import { useEffect, useState } from "react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import prettyHtml from "json-pretty-html";
import ReactPlayer from "react-player";
import "./App.css";

const App = () => {
  const [videoPlaying, changeVideoPlaying] = useState(false);
  const [exampleJSON, changeExampleJSON] = useState([]);


  const getExample = async () => {
    await axios
      .get(
        process.env.REACT_APP_NODE_ENV === "production"
          ? "https://wow.dylansevier.com/wows/random"
          : "https://wow.dylansevier.com/wows/random"
      )
      .then((res) => res.data)
      .then((data) => {
        changeExampleJSON(data[0]);

        const previewEl = document.getElementsByClassName(
          "react-player__preview"
        );

        if (previewEl[0]) {
          previewEl[0].style.backgroundImage = `url(${data[0].poster}), url(${data[0].poster}) !important;`;
        }
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    getExample();
  }, []);

  return (
    <div className="main_wrapper">
      <p>Refresh For A New Owen Wilson WOW:</p>
      {exampleJSON && exampleJSON.video && exampleJSON.poster ? (
        <div className="example_wrapper">
          <div className="interactive_wrapper">
            <div
              className="player-wrapper"
              style={{ backgroundImage: "url(" + exampleJSON.poster + ")" }}
            >
              <div className="blur">
                <ReactPlayer
                  playing={true}
                  onClickPreview={() => changeVideoPlaying(true)}
                  onEnded={() => changeVideoPlaying(false)}
                  loop={true}
                  url={exampleJSON.video["360p"]}
                  controls={true}
                  light={exampleJSON.poster}
                  style={{ maxWidth: "600px", overflow: "hidden" }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="example_wrapper">
          <ClipLoader color={"#000"} loading={true} size={100} />
        </div>
      )}
    </div>
  );
};

export default App;
